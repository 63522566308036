












































































































































































import { BrowserJsPlumbInstance } from '@jsplumb/browser-ui'
import * as jsPlumb from '@jsplumb/browser-ui'
// import Panzoom, { PanzoomObject, PanzoomOptions } from '@panzoom/panzoom'
import panzoom, { PanZoom } from 'panzoom'
import { Connection } from '@jsplumb/core'
import { nanoid } from 'nanoid'
import Vue from 'vue'
import ObjectNode from './objects-node.vue'
import ImportFileNodeEditor from './import-file-node-editor.vue'
import ModalEdit from './objects/modal-edit.vue'
import {
  AvailableRepres,
} from '@/models/importfile'
import { InjectAPI } from '@/utils/api'
import { clamp } from '@/utils'
import {
  ImportFileObjectComponentLink, ImportFileObjectComponentRepre, ImportTemplateGraphEdge, ImportTemplateGraphNode,
} from '@/models/imports'

const MAX_ZOOM = 1
const MIN_ZOOM = 0.25

const clampZoom = (number: number) => clamp(number, MIN_ZOOM, MAX_ZOOM)

interface Data {
  instance: null | BrowserJsPlumbInstance;
  panzoom: null | PanZoom;

  availableRelations: Record<string, string[]>

  overlay: boolean;

  jsPlumbReady: boolean;
  ready: boolean;

  mouseX: number;
  mouseY: number;

  zoom: number;

  initialRelationsFetched: boolean;
}

export default Vue.extend({
  name: 'Objects',
  components: {
    ObjectNode,
  },
  mixins: [
    InjectAPI,
  ],
  data(): Data {
    return {
      instance: null,
      panzoom: null,

      overlay: true,

      jsPlumbReady: false,
      availableRelations: {},
      ready: false,

      mouseX: 0,
      mouseY: 0,

      zoom: 1,

      initialRelationsFetched: false,
    }
  },
  computed: {
    availableNodes(): any {
      const { nodes } = this.$accessor.importfile

      const ids = nodes.map((node) => ({ kid: node.class.kid, id: node.id }))

      const availableNodes = ids.map(({ kid, id }) => ({
        id,
        text: this.$accessor.representations.get(kid),
      }))

      return availableNodes
    },
    roundedZoom(): number {
      return Math.round(this.zoom * 100)
    },
    mousePosition(): string {
      const canvas = this.$refs.canvas as Element

      const rect = canvas.getBoundingClientRect()
      const x = Math.round(this.mouseX - rect.left)
      const y = Math.round(this.mouseY - rect.top)
      return `${x}px, ${y}px`
    },
    canvasStyle(): any {
      return {
        height: `${this.CANVAS_SIZE}px`,
        width: `${this.CANVAS_SIZE}px`,
      }
    },
    CANVAS_SIZE(): number {
      return this.$accessor.importfile.canvasSize
    },
    headers(): string[] {
      return this.$accessor.importfile.headers
    },
    selectedNodeId(): string {
      return this.$accessor.importfile.selectedNodeId
    },
    selectedNode: {
      get(): ImportTemplateGraphNode | undefined {
        return this.$accessor.importfile.nodes.find((node) => node.id === this.selectedNodeId)
      },
    },
    nodes(): ImportTemplateGraphNode[] {
      return this.$accessor.importfile.nodes
    },
    positions(): Record<string, [number, number]> {
      return this.$accessor.importfile.positions
    },
    edges(): ImportTemplateGraphEdge[] {
      return this.$accessor.importfile.edges
    },
  },
  mounted(): void {
    const canvas = this.$refs.canvas as HTMLElement

    this.panzoom = panzoom(canvas, {
      zoomSpeed: 1,
      smoothScroll: false,
      zoomDoubleClickSpeed: 1,
      maxZoom: MAX_ZOOM,
      minZoom: MIN_ZOOM,
    })

    this.panzoom.on('zoom', () => {
      if (this.panzoom && this.instance) {
        const transform = this.panzoom.getTransform()
        this.zoom = transform.scale
        this.instance.setZoom(transform.scale)
      }
    })

    this.instance = jsPlumb.newInstance({
      endpoint: { type: 'Dot', options: { radius: 8, cssClass: 'dot-endpoint' } },
      anchor: 'Continuous',
      connectionOverlays: [
        {
          type: 'Arrow',
          options: {
            location: 1,
            id: 'arrow',
            length: 20,
            foldback: 1,
          },
        },
        {
          type: 'Label',
          options: {
            label: 'est composé de',
            location: 0.5,
            id: 'link-label',
            cssClass: 'composed-of',
          },
        },
      ],
      dragOptions: {
        grid: [25, 25],
      },
      connector: {
        type: 'Bezier',
        options: {
          // @ts-ignore
          curviness: 60,
        },
      },
      endpointStyle: { fill: 'transparent', outlineStroke: 'black', outlineWidth: 1 },
      container: canvas,
    })

    jsPlumb.ready(async () => {
      if (this.jsPlumbReady) {
        return
      }

      await this.$nextTick()
      await this.makeExistingLinks()
      await this.$nextTick()
      await this.fetchInitialRelations()
      await this.$nextTick()
      this.repaint()

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            // redraw links when canvas is displayed
            this.repaint()
          }
        })
      }, {
        root: document.documentElement,
      })

      const element = document.getElementById('canvas')
      if (element) {
        observer.observe(element)
      }

      this.instance?.bind('connection', (info) => {
        this.resetPossibleConections()

        const con: Connection = info.connection

        const { sourceId, targetId } = con.parameters

        const edge: ImportTemplateGraphEdge = {
          id: nanoid(),
          source: sourceId,
          target: targetId,
          subtype: this.$api.constants.relations.COMPOSED_OF as AvailableRepres,
        }
        this.$accessor.importfile.ADD_EDGE(edge)
      })

      this.instance?.bind('beforeDrop', (info) => {
        const { sourceId } = info.connection.parameters
        const targetId = info.dropEndpoint.element.id

        return this.canDrop(sourceId, targetId)
      })

      this.instance?.bind('beforeDrag', (params) => {
        const { source }: { source: HTMLElement } = params
        const { id } = source

        // Find original node
        // const foundNode = this.nodes.find(node => node.id  === id)

        // Find it's possible connections
        const possibleConnections = this.availableRelations[id]

        if (possibleConnections) {
          // Find potential elements
          const potentialNodes = this.nodes.filter((node) => possibleConnections.includes(node.type))

          // Assign class to possible elements
          potentialNodes.forEach((node) => {
            const { id } = node

            const element = document.getElementById(id)

            element?.classList.add('valid-destination')
          })
        }
      })

      this.instance?.bind('connection:abort', () => {
        this.resetPossibleConections()
      })

      this.ready = true
      await this.$nextTick()
      this.jsPlumbReady = true
    })
  },
  methods: {
    updateZoom(x: number, y: number, scale: number, smooth = true) {
      console.log('scale', scale)
      console.log('x', x)
      console.log('y', y)
      if (this.instance && this.panzoom) {
        if (smooth) {
          this.panzoom.smoothZoomAbs(x, y, scale)
        } else {
          this.panzoom.zoomAbs(x, y, scale)
        }
      } else {
        console.log('Cannot find scale')
      }
    },
    getCenter() {
      const objectsWrapper = document.getElementById('objects-wrapper')

      if (objectsWrapper && this.panzoom) {
        const transform = this.panzoom.getTransform()
        const x = (objectsWrapper.getBoundingClientRect().width / 2) + (transform.x)
        const y = (objectsWrapper.getBoundingClientRect().height / 2) + (transform.y)

        return { x, y }
      }
      throw new Error('Oops')
    },
    zoomIn() {
      if (this.panzoom) {
        const transform = this.panzoom.getTransform()
        if (transform) {
          console.log('transform', transform)
          this.updateZoom(transform.x + (1126 / 2), transform.y + (657 / 2), clampZoom(this.zoom + 0.05))
        } else {
          console.log('no transform')
        }
      } else {
        console.log('no panzoom')
      }
    },
    zoomOut() {
      if (this.panzoom) {
        const transform = this.panzoom.getTransform()
        if (transform) {
          console.log('transform', transform)
          this.updateZoom(transform.x - (1126 / 2), transform.y - (657 / 2), clampZoom(this.zoom - 0.05))
        } else {
          console.log('no transform')
        }
      } else {
        console.log('no panzoom')
      }
    },
    zoomReset(smooth = true) {
      if (this.panzoom) {
        const transform = this.panzoom.getTransform()
        if (transform) {
          console.log('transform', transform)
          this.updateZoom(transform.x - (1126 / 2), transform.y - (657 / 2), 1, smooth)
        } else {
          console.log('no transform')
        }
      } else {
        console.log('no panzoom')
      }
    },
    async fetchInitialRelations() {
      // Import relations
      const promises = []
      for (const node of this.nodes) {
        promises.push(this.importAvailableRelations(node))
      }
      await Promise.all(promises)
      this.initialRelationsFetched = true
    },
    focusNode(id: string) {
      const position = this.$accessor.importfile.positions[id]
      const objectsWrapper = document.getElementById('objects-wrapper')
      const node = document.getElementById(id)

      if (position && objectsWrapper && node) {
        const nodeWidth = node.getBoundingClientRect().width / 2
        const nodeHeight = node.getBoundingClientRect().height / 2

        const nodeX = Number.parseInt(node.style.left.replace('px', ''), 10)
        const nodeY = Number.parseInt(node.style.top.replace('px', ''), 10)

        const wrapperWidth = objectsWrapper.getBoundingClientRect().width / 2
        const wrapperheight = objectsWrapper.getBoundingClientRect().height / 2

        const nodeRealX = nodeX
        const nodeRealY = nodeY

        const differenceX = wrapperWidth - nodeRealX - nodeWidth
        const differenceY = wrapperheight - nodeRealY - nodeHeight

        this.zoomReset(false)
        this.panzoom?.smoothMoveTo(differenceX, differenceY)
      }
    },
    onMouseMoveOnCanvas(e: MouseEvent) {
      this.mouseX = e.clientX
      this.mouseY = e.clientY
    },
    canDrop(sourceId: string, targetId: string): boolean {
      const possibleConnections = this.availableRelations[sourceId]
      const possibleDestination = this.nodes.find((node) => node.id === targetId)

      if (possibleConnections.includes(possibleDestination?.type ?? '')) {
        return true
      }

      return false
    },

    resetPossibleConections(): void {
      document.querySelectorAll('.valid-destination').forEach((el) => {
        el.classList.remove('valid-destination')
      })
    },
    async importAvailableRelations(node: ImportTemplateGraphNode): Promise<void> {
      try {
        // KNODE.COMPOSED_OF
        const object = await this.$api.objectDef({
          isa: node.class.kid,
        })

        // @ts-ignore
        if (object.link.list.from?.[this.$api.constants.relations.DEFINED_BY]?.list) {
        // @ts-ignore
          this.availableRelations[node.id] = Object
          // @ts-ignore
            .values(object.link.list.from[this.$api.constants.relations.DEFINED_BY]?.list ?? {})
          // @ts-ignore
            .map((x) => x.objid + (x.linker_kid ? `::${x.linker_kid}` : '::0'))
        }
      } catch (error) {
        console.log('Node not found', error)
      }
    },
    makeExistingLinks(): Promise<boolean> {
      return new Promise((resolve) => {
        if (this.instance) {
          this.instance.batch(async () => {
            const repreToFetch: string[] = []

            // Generate view
            if (this.instance && this.edges) {
              for (const node of this.nodes) {
                repreToFetch.push(node.class.kid)

                const position = this.$accessor.importfile.getPosition(node.id)

                this.placeNode(node, position)
                this.makeNodeInteractive(node, position)
              }

              for (const e of this.edges) {

                const source = document.getElementById(e.source) as HTMLElement
                const target = document.getElementById(e.target) as HTMLElement

                if (!source) {
                  this.$accessor.importfile.REMOVE_EDGES_TO(e.source)
                }

                if (!target) {
                  this.$accessor.importfile.REMOVE_EDGES_TO(e.target)
                }

                if (source && target) {
                  this.instance.connect({
                    source,
                    target,
                  })
                } else {
                  console.error(`Either ${e.source} or ${e.target} cannot be found`)
                }
              }
            }

            this.overlay = false

            await this.$accessor.representations.fetchAll(repreToFetch)

            return resolve(true)
          })
        }

        this.overlay = false

        return resolve(true)
      })
    },
    repaint(): void {
      const canvas = this.$refs.canvas as Element

      this.instance?.revalidate(canvas)
      this.instance?.repaintEverything()
    },
    recenterView(): void {
      const node = this.nodes.find((node) => node.master) ?? this.nodes[0]

      this.focusNode(node.id)
    },
    calculateGraphSize(): [number, number, number, number] {
      let minX: undefined | number
      let minY: undefined | number
      let maxX: undefined | number
      let maxY: undefined | number

      this.nodes.forEach((node) => {
        const element = document.getElementById(node.id)
        if (!element) {
          return
        }

        const x = element.offsetLeft
        const y = element.offsetTop
        const width = element.clientWidth
        const height = element.clientHeight

        if (minX === undefined) {
          minX = x
        }

        if (minY === undefined) {
          minY = y
        }

        if (maxX === undefined) {
          maxX = x + width
        }

        if (maxY === undefined) {
          maxY = y + height
        }

        if (x < minX) {
          minX = x
        }

        if (x + width > maxX) {
          maxX = x + width
        }

        if (y < minY) {
          minY = y
        }

        if (y + height > maxY) {
          maxY = y + height
        }
      })

      if (minX && minY && maxX && maxY) {
        console.log([minX, minY, maxX, maxY])

        // const middleAdjustedX = ((maxX - minX) / 2) - canvas.clientLeft
        // const middleAdjustedY = ((maxY - minY) / 2) - canvas.clientTop

        // this.panzoom.pan(middleAdjustedX, middleAdjustedY)
        return [minX, minY, maxX, maxY]
      }
      return [0, 0, 0, 0]
    },
    adjustView(): void {
      /* const master = this.nodes.find(node => node.master)

      if (!master) {
        this.$toast.error('Aucun noeud maître trouvé !')
        return
      }

      const element = document.getElementById(master.id)
      if (!element) {
        return
      }

      const x = element.offsetLeft
      const y = element.offsetTop
      const width = element.clientWidth
      const height = element.clientHeight

      this.panzoom?.pan(-(x + (width / 2)), -(y + (height / 2))) */
    },
    onNodeDelete(nodeId: string): void {
      const node = this.nodes.find((node) => node.id === nodeId)

      if (node) {
        const element = document.getElementById(node.id)
        if (element && this.instance) {
          this.instance.removeAllEndpoints(element)
          this.instance._removeElement(element)
          this.$accessor.importfile.REMOVE_NODE(nodeId)
          this.$accessor.importfile.REMOVE_EDGES_TO(nodeId)
        } else {
          console.error('Element not found')
        }
      } else {
        console.error('Node not found')
      }
    },
    setSelectedNode(nodeId: string): void {
      this.$accessor.importfile.SET_SELECTED_NODE_ID(nodeId)
    },
    async onNodeEdit(nodeId: string): Promise<void> {
      this.setSelectedNode(nodeId)
      await this.$dialog.open(ModalEdit)
    },
    async generateNode(): Promise<void> {
      try {
        const node = await this.$dialog.open<ImportTemplateGraphNode>(ImportFileNodeEditor, {
          component: {
            id: nanoid(),
          },
        })
        if (node !== 'close') {
          await this.importAvailableRelations(node)
          await this.insertNode(node, [200, 100])
        }
      } catch {
        console.log('cancelled')
      }
    },
    makeNodeInteractive(node: ImportTemplateGraphNode, position: [number, number]): void {
      if (!position) {
        position = [0, 0]
      }

      const el = document.getElementById(node.id)

      if (el) {
        this.instance?.makeSource(el, {
          anchor: 'Continuous',
          filter: '.graph-exclude',
          filterExclude: true,
          paintStyle: {
            stroke: '#2e6f9a',
            fill: 'green',
          },
          cssClass: 'panzoom-exclude',
          parameters: {
            sourceId: node.id,
          },
        })

        this.instance?.makeTarget(el, {
          allowLoopback: false,
          parameters: {
            targetId: node.id,
          },
          cssClass: 'panzoom-exclude',
        })
      }
    },
    placeNode(node: ImportTemplateGraphNode, position: [number, number]): void {
      if (!position) {
        position = [0, 0]
      }

      const el = document.getElementById(node.id)

      if (el) {
        el.style.left = `${position[0] - (el.offsetWidth / 2)}px`
        el.style.top = `${position[1] - (el.offsetHeight / 2)}px`
      }
    },
    async insertNode(node: ImportTemplateGraphNode, position: [number, number]): Promise<void> {
      this.$accessor.importfile.ADD_NODE(node)

      await this.$nextTick()

      this.placeNode(node, position)
      this.makeNodeInteractive(node, position)

      await this.$nextTick()
    },
    isRepre(link: ImportFileObjectComponentLink | ImportFileObjectComponentRepre): link is ImportFileObjectComponentRepre {
      return (link.type === 'KREPRE')
    },
    async layoutNodes() {
      const graph = await this.$accessor.importfile.layoutNodes()

      console.log('graph', graph)

      this.nodes.forEach((node) => {
        const el = document.getElementById(node.id)

        if (el && graph.width && graph.height) {
          const position = this.positions[node.id]

          const nodePositionRelativeX = position[0] - (el.offsetWidth / 2)
          const nodePositionRelativeY = position[1] - (el.offsetHeight / 2)

          // const middleCanvas = this.CANVAS_SIZE / 2
          // const middleGraphWidth = graph.width / 2
          // const middleGraphHeight = graph.height / 2

          el.style.left = `${/* middleCanvas -  middleGraphWidth + */nodePositionRelativeX}px`
          el.style.top = `${/* middleCanvas -  middleGraphHeight + */nodePositionRelativeY}px`
        }
      })
      await this.$nextTick()
      this.repaint()
      this.recenterView()
    },
  },
})
