













































































































































































































import { AutocompleteItem, representationsV4 } from '@knitiv/api-client-javascript'
import Vue, { PropType } from 'vue'
import MfInput from '@/components/mf/mf.vue'
import { ImportTemplateGraphNode } from '@/models/imports'
import { RepresentationCommitModel, SuggestionHeader } from '@/store/importfile'
import { InjectAPI } from '@/utils/api'

interface Data {
  availableRepresentations: { name: string; value: string; }[];
  representationModel: RepresentationCommitModel;
}

export default Vue.extend({
  components: {
    MfInput,
  },
  mixins: [InjectAPI],
  props: {
    resolve: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    reject: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
  },
  data(): Data {
    return {
      availableRepresentations: [
        {
          name: 'Universel',
          value: 'K_NODE;UNIVERSEL',
        },
        {
          name: 'Acronyme',
          value: 'K_NODE;ACRONYM',
        },
        {
          name: 'Code',
          value: 'K_NODE;CODE',
        },
      ],
      representationModel: {
        lang: representationsV4.UNIVERSEL,
        column: {
          value: '',
          type: 'column',
        },
        searchInput: '',
      },
    }
  },
  computed: {
    isV3(): boolean {
      return this.$api.backendEngine === 3
    },
    dialogLinker: {
      get(): AutocompleteItem | undefined {
        return this.selectedNode?.linker
      },
    },
    dialogReadonly: {
      get(): boolean | undefined {
        return this.selectedNode?.action === 'read'
      },
      set(value: boolean): void {
        this.$accessor.importfile.SET_SELECTED_NODE_READONLY(value)
      },
    },
    dialogMaster: {
      get(): boolean | undefined {
        return this.selectedNode?.master
      },
      set(value: ImportTemplateGraphNode['master']): void {
        this.$accessor.importfile.SET_SELECTED_NODE_MASTER(value)
      },
    },
    dialogExitIfFound: {
      get(): boolean | undefined {
        return this.selectedNode?.exitIfFound
      },
      set(value: ImportTemplateGraphNode['exitIfFound']): void {
        this.$accessor.importfile.SET_SELECTED_NODE_EXIT_IF_FOUND(value)
      },
    },
    dialogRepreCanBeEmpty: {
      get(): boolean | undefined {
        return this.selectedNode?.repreCanBeEmpty
      },
      set(value: ImportTemplateGraphNode['repreCanBeEmpty']): void {
        this.$accessor.importfile.SET_SELECTED_NODE_REPRECANBEEMPTY(value)
      },
    },
    itemIcons(): Record<SuggestionHeader['type'], string> {
      return this.$accessor.importfile.itemIcons
    },
    itemType(): Record<SuggestionHeader['type'], string> {
      return this.$accessor.importfile.itemType
    },
    repreHeaders(): SuggestionHeader[] {
      return this.$accessor.importfile
        .headerNamesSuggestions
        .filter((h) => h.type !== 'filter')
        .filter((h) => h.value !== '')
    },
    selectedNode(): ImportTemplateGraphNode | undefined {
      const nodeId = this.$accessor.importfile.selectedNodeId

      return this.$accessor.importfile.nodes.find((node) => node.id === nodeId)
    },
  },
  methods: {
    onMfChange(value: AutocompleteItem): void {
      console.log('value', value)
      this.$accessor.importfile.SET_SELECTED_NODE_LINKER(value)
    },
    addRepresentation(): void {
      this.$accessor.importfile.ADD_REPRESENTATION_TO_SELECTED_NODE(this.representationModel)

      this.representationModel.lang = representationsV4.UNIVERSEL
      this.representationModel.column = {
        value: '',
        type: 'column',
      }
      this.representationModel.searchInput = null

      console.log('this.representationModel', JSON.stringify(this.representationModel))
    },
    removeRepresentation(index: number): void {
      this.$accessor.importfile.REMOVE_REPRESENTATION_FROM_SELECTED_NODE(index)
    },
  },
})
