




import Vue from 'vue'
import templatePage from '@/components/importfile/templatePage.vue'

export default Vue.extend({
  components: {
    templatePage,
  },
})
