

















































































import Vue, { PropType } from 'vue'
import NodeBase from '@/components/jsplumb/node-base.vue'
import { ImportTemplateGraphNode } from '@/models/imports'

interface Data {
  isLoading: boolean;

  showMenu: boolean;
  x: number;
  y: number;

  items: any[]
}

export default Vue.extend({
  components: {
    NodeBase,
  },
  props: {
    node: {
      required: true,
      type: Object as PropType<ImportTemplateGraphNode>,
    },
  },
  data(): Data {
    return {
      isLoading: false,

      showMenu: false,
      x: 0,
      y: 0,
      items: [
        {
          title: 'Supprimer',
          action: () =>
            // @ts-ignore
            this.deleteNode(),

        },
      ],
    }
  },
  computed: {
    nodeName(): string {
      const name = this.$accessor.representations.get(this.node.class.kid)
      if (this.node.linker) {
        const linker = this.$accessor.representations.get(this.node.linker.kid)
        return `${name} :: ${linker}`
      }
      return name
    },
    headers(): string[] {
      return this.$accessor.importfile.headers
    },
    repreCount(): number {
      return this.node.representations.length
    },
    action(): string {
      // @ts-ignore
      return this.node.action ?? 'Aucune action'
    },
  },
  mounted() {
    this.isLoading = true
  },
  methods: {
    openOptions() {
      this.setSelectedNode(this.node.id)
      this.$emit('edit', this.node.id)
    },
    setSelectedNode(nodeId: string) {
      console.log('click', nodeId)
      this.$accessor.importfile.SET_SELECTED_NODE_ID(nodeId)
    },
    deleteNode() {
      console.log('delete node')
      this.$emit('delete', this.node.id)
    },
    show(e: MouseEvent) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
  },
})
