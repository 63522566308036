


























































































import dayjs from 'dayjs'
import Vue from 'vue'
import { Mutator, mutatorFunctions } from '@/models/imports'
import { SuggestionHeader } from '@/store/importfile'

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mutatorFunctions,
    }
  },
  computed: {
    itemIcons() {
      return this.$accessor.importfile.itemIcons
    },
    itemType() {
      return this.$accessor.importfile.itemType
    },
    headersSuggestion(): SuggestionHeader[] {
      const result = this.$accessor.importfile.headerNamesSuggestions

      const mutator = this.$accessor.importfile.mutators?.find((mutator) => mutator.id === this.id)

      const filtered = result.filter((r) => r.value !== mutator?.output).filter((r) => r.value !== '')

      if (mutator) {
        mutator.inputs.forEach((input) => {
          filtered.push({
            value: input,
            type: 'mutator',
          })
        })
      }

      // console.log('filtered', filtered)

      return filtered
    },
    output: {
      get(): string {
        const output = this.$accessor.importfile.mutators?.find((mod) => mod.id === this.id)
        return output?.output ?? ''
      },
      set(newValue: string) {
        this.$accessor.importfile.SET_MODIFIER_OUTPUT({
          id: this.id,
          value: newValue,
        })
      },
    },
    modifier: {
      get(): Mutator['modifier'] {
        return this.$accessor.importfile.mutators?.find((mod) => mod.id === this.id)?.modifier ?? 'remove_spaces'
      },
      set(newValue: Mutator['modifier']) {
        // console.log('value', newValue)
        this.$accessor.importfile.SET_MODIFIER_MODIFIER({
          id: this.id,
          value: newValue,
        })
      },
    },
    inputs: {
      get(): Mutator['inputs'] {
        return this.$accessor.importfile.mutators?.find((mod) => mod.id === this.id)?.inputs ?? []
      },
      set(newValue: Mutator['inputs']) {
        // console.log('value', newValue)
        this.$accessor.importfile.SET_MODIFIER_INPUTS({
          id: this.id,
          value: newValue,
        })
      },
    },
  },
  methods: {
    deleteModifier() {
      this.$accessor.importfile.REMOVE_MUTATOR(this.id)
    },
    applyModifier(id?: string) {
      // console.log('Applying modifier to', id)
      if (id === undefined) {
        id = this.id
      }

      const $mutator = this.$accessor.importfile.mutators?.find((mutator) => mutator.id === id)

      if (!$mutator) {
        return 'aaa'
      }

      const { excel } = this.$accessor.importfile

      const headerRow = this.$accessor.importfile.infos.headersLine
      const startRow = this.$accessor.importfile.infos.startLine

      if (Object.values(excel.rows).length <= 0) {
        return 'Chargez une source de référence'
      }

      // console.log('excel.rows[headerRow]', excel.rows)
      // console.log('excel.rows[headerRow]', headerRow)
      // console.log('excel.rows[headerRow]', excel.rows[headerRow])

      if (!excel.rows[headerRow] || !excel.rows[startRow]) {
        return 'Impossible de prévisualiser'
      }

      const firstRow = Object.entries(excel.rows[headerRow]).map(([, value]) => value[0])
      const secondRow = Object.entries(excel.rows[startRow]).map(([, value]) => value[0])

      const $row: Record<string, string> = {}
      firstRow.forEach((eachItem, i) => {
        $row[eachItem] = secondRow[i]
      })

      // console.log('firstRow', firstRow)
      // console.log('$row', $row)
      const inputs: string[] = []

      // eslint-disable-next-line
      inputs.push(...$mutator.inputs.map(input => {
        if ($row[input]) {
          return $row[input]
        }

        const mutator = this.$accessor.importfile.mutators?.find((mod) => mod.output === input)
        if (mutator) {
          // console.log('mutator.output', mutator.output)
          // console.log('input', input)

          return this.applyModifier(mutator.id)
        }

        return ''
      }))

      const fns = {
        str_pad_with_two_zero: () => this.padWithTwoZero(inputs),
        remove_spaces: () => this.removeSpaces(inputs),
        trim_one_left: () => this.trimOneLeft(inputs),
        concat_hyphen: () => this.concatenateWithHyphen(inputs),
        format_date: () => this.formatDate(inputs),
        remove_specials_characters: () => this.removeSpecialsCharacters(inputs),
        select_last: () => this.selectLast(inputs),
        today: () => this.dateToday(),
        now: () => this.dateNow(),
        default: () => $row,
      }
      try {
        const value = fns[$mutator.modifier]?.() ?? fns.default()
        // console.log('value', value)
        return value
      } catch {
        return '<Erreur>'
      }
    },

    padWithTwoZero(args: string[]) {
      if (args[0]) {
        return args[0].toString().replaceAll(' ', '').padStart(2, '0')
      }

      throw new Error('Arguments invalides')
    },

    concatenateWithHyphen(args: string[]) {
      if ((args[0]) && (args[1])) {
        return `${args[0]}-${args[1]}`
      }

      if ((args[0])) {
        return args[0]
      }

      throw new Error('Arguments invalides')
    },

    trimOneLeft(args: string[]) {
      if ((args[0])) {
        return args[0].toString().slice(1)
      }

      throw new Error('Arguments invalides')
    },

    removeSpaces(args: string[]) {
      if ((args[0])) {
        return args[0].toString().replaceAll(' ', '')
      }

      throw new Error('Arguments invalides')
    },

    removeSpecialsCharacters(args: string[]) {
      if ((args[0])) {
        return args[0].toString().replaceAll(/[$+%*]/gm, '')
      }

      throw new Error('Arguments invalides')
    },

    formatDate(args: string[]) {
      if (!(args[0])) {
        throw new Error('Arguments invalides')
      }
      const $unformatedDate = args[0]
      const $dateTime = dayjs($unformatedDate)

      if ($dateTime) {
        return $dateTime.format('YYYY-MM-DD')
      }

      throw new Error('Arguments invalides')
    },

    selectLast(args: string[]) {
      return args[0]
    },

    dateToday() {
      return dayjs().format('YYYY-MM-DD')
    },

    dateNow() {
      return dayjs().format('YYYY-MM-DD')
    },
  },
})
