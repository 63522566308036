







































import { invert } from 'lodash'
import Vue from 'vue'
import { HeaderFilters } from '@/models/imports'
import { SuggestionHeader } from '@/store/importfile'

interface Data {
  selectedHeaders: Record<string, string>
}

export default Vue.extend({
  data(): Data {
    return {
      selectedHeaders: {},
    }
  },
  computed: {
    itemIcons() {
      return this.$accessor.importfile.itemIcons
    },
    itemType() {
      return this.$accessor.importfile.itemType
    },
    isExcelLoaded() {
      const { excel } = this.$accessor.importfile
      return Object.values(excel.rows).length
    },
    filters(): HeaderFilters | undefined {
      return this.$accessor.importfile.filters
    },
    headerSuggestions(): SuggestionHeader[] {
      return this.$accessor
        .importfile
        .headerNamesSuggestions
        .filter((h) => h.type !== 'filter')
        .filter((h) => h.value !== '')
    },
  },
  mounted() {
    if (this.filters) {
      // populate inital headers
      Object.values(this.filters).forEach((filterKey) => {
        this.$set(this.selectedHeaders, filterKey, '')
      })
    }
  },
  methods: {
    importFilter(filterToReplace: string) {
      console.log('filterToReplace', filterToReplace)
      console.log(this.selectedHeaders[filterToReplace])

      // import data modifiers
      const modifiers = this.$accessor.importfile.mutators

      modifiers?.forEach((modifier) => {
        modifier.inputs.forEach((input, index) => {
          // If the input is a modifier
          if (input === filterToReplace) {
            console.log(`Replacing ${modifier.modifier}:${input} with ${this.selectedHeaders[filterToReplace]}`)
            this.$accessor.importfile.REPLACE_INPUT_AT({
              index,
              id: modifier.id,
              value: this.selectedHeaders[filterToReplace],
            })
          }
        })
      })

      const { nodes } = this.$accessor.importfile

      nodes.forEach((node) => {
        node.representations.forEach((repre, index) => {
          if (repre.name === filterToReplace) {
            console.log(`Replacing ${node.class.kid}:${repre.name} with ${this.selectedHeaders[filterToReplace]}`)
            this.$accessor.importfile.REPLACE_NODE_REPRE_AT({
              index,
              id: node.id,
              value: this.selectedHeaders[filterToReplace],
            })
          }
        })
      })

      const filters = invert(this.$accessor.importfile.filters ?? {})

      this.$accessor.importfile.REMOVE_HEADER_FILTER(filters[filterToReplace])
    },
  },
})
