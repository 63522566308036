import Vue from 'vue'
import { Route } from 'vue-router'
import { DicoEntry } from '@knitiv/api-client-javascript'
import { events, watcher, watchers } from '@/models/actions'
import { N_MAX_CARDINALITY } from '@/constants/app'

/**
 * Cast object to a type using a requierd property
 * @param object - The object to check
 * @param property - The property to check
 *
 * @example
 * isType<position>(myPos, 'type')
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isType<T>(object: any, property: string): object is T {
  if (!object) { return false }
  return property in object
}

export class bootstrapComponent extends Vue {
  properties!: any

  key!: string

  events!: events | undefined

  components!: any

  watch!: watchers

  $route!: Route

  mounted(): void {
    console.warn('hooooooooooo')
    /* ** Bind default values from user template ** */
    const { pageId } = this.$route.params

    // Set default values on load
    console.log(`Settings default values again for ${this.key}`)
    if (this.properties && Object.entries(this.properties).length > 0) {
      Object.entries(this.properties)
        .forEach(([key, value]) => {
          const payload = {
            componentId: this.key,
            propertyValue: value,
            propertyName: key,
          }

          this.$store.commit(`${pageId}/SET_VALUE`, payload)
        })
    }
    /* ********************************************* */

    // eslint-disable-next-line
    this.events?.mounted?.call(this)
  }

  created(): void {
    /* ** Check component ** */
    // const { $options, components } = this
    // const { isFinal } = $options
    //
    // if (isFinal && (components && Object.keys(components).length)) {
    //   const error = `Impossible d'avoir des enfants pour le composant "${data.key}" de type ${data.type}`
    //   console.warn(error)
    //   this.error = error
    // }
    /* ******************** */

    /* ** Bind computed to the store ** */

    const { pageId } = this.$route.params

    if (this.watch) {
      Object.entries(this.watch)
        .forEach(([key, value]: [string, watcher]) => {
          this.$store.watch(
            () => this.$store.getters[`${pageId}/getValue`]({
              componentId: value.id,
              propertyName: value.property,
            }),
            (newValue, oldValue) => {
              // @ts-ignore
              if (this.watch[key].handler) {
                // eslint-disable-next-line unicorn/prefer-prototype-methods
                this.watch[key].handler
                  .call(this, newValue, oldValue)
              }
            },
            {
              deep: true,
            },
          )
        })
    }
    /* ******************************* */

    // eslint-disable-next-line
    this?.events?.created?.call(this)
  }

  bindEvent(events: events, event: string): void {
    if (events && events[event]) {
      // eslint-disable-next-line unicorn/prefer-prototype-methods
      events[event].call(this)
    }
  }
}

/**
 * Bind a value to its reference in the store
 * @param key
 */
// TODO
// export function bindData (key: string) {
//   return {
//     get () {
//       const { pageId } = this.$route.params
//       return this.$store.getters[`${pageId}/getValue`]({
//         componentId: this.data.key,
//         propertyName: key
//       })
//     },
//     set (value) {
//       console.log(`Setting ${this.data.key}.${key} = ${value}`)
//       const { pageId } = this.$route.params
//       this.$store.commit(`${pageId}/SET_VALUE`, {
//         componentId: this.data.key,
//         propertyValue: value,
//         propertyName: key
//       })
//     }
//   }
// }

export function formatNodeName(node: DicoEntry): string {
  let { name } = node.objnum2_info
  if (node.linker_kid && node.linker_info) {
    name += `::${node.linker_info.name}`
  }
  return name
}

export function formatCardinality(from: number, to: number): string {
  const formattedTo = to === N_MAX_CARDINALITY ? '∞' : to
  return `${from}-${formattedTo}`
}

type ValueOf<T> = T[keyof T]

export function filterObject<T extends Record<string, any>>(obj: T, predicate: (key: keyof T, value: ValueOf<T>) => boolean): T {
  const result = {} as T
  const keys = Object.keys(obj)
  const len = keys.length
  for (let i = 0; i < len; i++) {
    const key = keys[i]
    if (predicate(key, obj[key])) {
      // @ts-ignore
      result[key] = obj[key]
    }
  }
  return result
}

// TODO make type
export const ObjectToArray = (data: Record<any, any>, keyToTranslate = 'key') => Object
  .entries(data)
  .map(([key, value]) => Object.assign(value, { [keyToTranslate]: key }))

export const filterRecord = <T>(data: Record<string | number, T>, predicate: (arg: T) => boolean) =>
  // eslint-disable-next-line unicorn/no-array-reduce
  Object.keys(data).reduce((acc, current) => {
    if (predicate(data[current])) {
      // @ts-ignore
      acc[current] = data[current]
    }
    return acc
  }, {})

export const clamp = (nb: number, min: number, max: number) => Math.min(Math.max(nb, min), max)

export const getFileExtension = (filename: string) => filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2)
