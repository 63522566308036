import { _isEmail } from '@/validators/helpers'
import { Validator } from '@/models/architecture'

export const isEmail: Validator = (errorMessage = 'La valeur doit être une adresse e-mail') => (v): string | boolean => {
  if (typeof v === 'string') {
    return _isEmail(v) ? true : errorMessage as string
  }
  return errorMessage as string
}

export const isRequired: Validator = () => (v): boolean | string => !!v || 'Ce champs est requis'

export function kid(value: string) {
  return /K_NODE;[\dA-Z]{18}/.test(value)
}
