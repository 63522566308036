






































import { AutocompleteItem } from '@knitiv/api-client-javascript'
import Vue, { PropType } from 'vue'
import { nanoid } from 'nanoid'
import MfInput from '@/components/mf/mf.vue'
import { ImportTemplateGraphNode } from '@/models/imports'

interface Data {
  graphNode: ImportTemplateGraphNode
}

export default Vue.extend({
  components: {
    MfInput,
  },
  props: {
    node: {
      type: Object as PropType<ImportTemplateGraphNode>,
      required: false,
      default(): ImportTemplateGraphNode {
        return {
          id: nanoid(),
          representations: [],
          class: {
            kid: '',
          },
          action: 'create/update',
          exitIfFound: false,
          master: false,
          repreCanBeEmpty: false,
          type: '',
        }
      },
    },
    id: {
      required: true,
      type: String as PropType<string>,
    },
    resolve: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    reject: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
  },
  data(): Data {
    return {
      graphNode: this.node,
    }
  },
  methods: {
    send(): any {
      console.log('this.id', this.id)
      return this.resolve(this.graphNode)
    },
    OnMfChange(mf: AutocompleteItem): void {
      console.log('on mf change')
      this.graphNode.class = mf
      this.graphNode.type = `${mf.kid}::0`
    },
  },
})
