


















































































































































































import Vue, { VueConstructor } from 'vue'
import { Pane, Splitpanes } from 'splitpanes'
import { Drop } from 'vue-easy-dnd'
import { ObjectInfoOutput, AutocompleteItem, SharedConstants } from '@knitiv/api-client-javascript'

import { flatten } from 'lodash'
import MfInput from '@/components/mf/mf.vue'
import Panel from '@/components/panel.vue'
import {
  BlocType, ImportTemplate, SavedImportTemplate, SavedImportTemplateValidator,
} from '@/models/imports'
import ExcelPreview from '@/components/importfile/excel-preview.vue'
import Informations from '@/components/importfile/viewer/informations.vue'
import DataMutator from '@/components/importfile/viewer/dataMutator.vue'
import HeaderFilters from '@/components/importfile/viewer/headerFilters.vue'
import Errors from '@/components/importfile/viewer/errors.vue'
import Objects from '@/components/importfile/viewer/objects.vue'
import { formatNodeName } from '@/utils'
import { InjectAPI } from '@/utils/api'
import { TemplateErrors } from '@/store/importfile'

const blocToTemplate: Record<BlocType, VueConstructor<Vue>> = {
  [BlocType.INFORMATIONS]: Informations,
  [BlocType.HEADER_FILTERS]: Informations,
  [BlocType.DATA_MUTATORS]: DataMutator,
  [BlocType.HEADER_FILTERS]: HeaderFilters,
  [BlocType.OBJECTS]: Objects,
  [BlocType.ERRORS]: Errors,
}

interface Data {
  id: string;
  dataImport: ObjectInfoOutput | null;
  N: number;

  searchText: string;
  open: any[];
  icons: Record<BlocType, any>;

  formatNodeName: any,

  isExcelLoading: boolean;

  fileId: string | null;

  orderFile: string;

  errors: TemplateErrors;
  unsubscribe: (() => void) | undefined;
}

interface Tab {
  name: string;
  to: string;
  id: string;
}

export default Vue.extend({
  components: {
    MfInput,
    Panel,
    Drop,
    Splitpanes,
    Pane,
    ExcelPreview,
    ...blocToTemplate,
  },
  mixins: [
    InjectAPI,
  ],
  props: {
    kid: {
      type: String,
      required: false,
      default: null,
    },
  },
  data(): Data {
    return {
      id: '',
      dataImport: null,
      N: SharedConstants.N_MAX_CARDINALITY,

      isExcelLoading: false,

      searchText: '',
      open: [],
      icons: {
        [BlocType.INFORMATIONS]: { icon: 'mdi-information', color: 'blue' },
        [BlocType.HEADER_FILTERS]: { icon: 'mdi-information', color: 'blue' },
        [BlocType.DATA_MUTATORS]: { icon: 'mdi-information', color: 'blue' },
        [BlocType.HEADER_FILTERS]: { icon: 'mdi-information', color: 'blue' },
        [BlocType.OBJECTS]: { icon: 'mdi-information', color: 'blue' },
        [BlocType.ERRORS]: { icon: 'mdi-information', color: 'blue' },
      },

      formatNodeName,

      fileId: null,

      orderFile: SharedConstants.nodes.DATAEXCH_FILE,

      errors: {
        filters: [],
        mutators: [],
        objects: [],
      },
      unsubscribe: undefined,
    }
  },

  computed: {
    errorNumber(): number {
      return Object.values(this.errors).flat(1).length
    },
    tabs(): Tab[] {
      return [
        {
          name: 'Informations',
          to: BlocType.INFORMATIONS,
          id: 'informations',
        },
        {
          name: 'Filtres de titre',
          to: BlocType.HEADER_FILTERS,
          id: 'header_filters',
        },
        {
          name: 'Mutateurs de données',
          to: BlocType.DATA_MUTATORS,
          id: 'data_mutators',
        },
        {
          name: 'Objets',
          to: BlocType.OBJECTS,
          id: 'objects',
        },
        {
          name: `Erreurs (${this.errorNumber})`,
          to: BlocType.ERRORS,
          id: 'errors',
        },
      ]
    },
    name(): string {
      return this.$accessor.importfile.name ?? 'Chargement...'
    },
    filtersLength(): number {
      return Object.keys(this.$accessor.importfile.filters ?? {}).length
    },
    filledHeaders(): string[] {
      return flatten(this.$accessor.importfile.nodes.map((node) => node.representations.map((repre) => repre.name)))
    },
    headerAndLine(): Record<string, string> {
      const assoc: Record<string, string> = {}

      this.$accessor.importfile.headers.forEach((header, index) => {
        assoc[header] = this.$accessor.importfile.repreHeaders[index]
      })

      return assoc
    },
    currentSheetId: {
      get(): number {
        return this.$accessor.importfile.infos.worksheetId
      },
      set(id: number) {
        this.$accessor.importfile.SET_INFO({ name: 'worksheetId', value: id })
      },
    },
    tab: {
      get(): number {
        return this.$accessor.importfile.tab
      },
      set(value: number) {
        this.$accessor.importfile.SET_TAB(value)
      },
    },
    infos(): ImportTemplate['infos'] {
      return this.$accessor.importfile.infos
    },
  },
  watch: {
    currentSheetId() {
      console.log('this.fileId', this.fileId)
      console.log('currentSheetId', this.currentSheetId)
      this.setupFile()
    },
  },

  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
    this.$accessor.importfile.RESET()
  },

  created() {
    this.unsubscribe = this.$store.subscribe(async (mutation) => {
      if (mutation.type.startsWith('importfile/')) {
        this.errors = await this.$accessor.importfile.fetchErrors()
      }
    })
  },

  mounted() {
    this.fileId = this.$route.query.fileId as (string | null)

    this.setupFile()

    if (this.kid) {
      this.$accessor.importfile.SET_TARGET(this.kid)
      this.setupTemplate()
    }
  },

  methods: {
    onExcelPreviewLoadingStatusChanged(value: boolean) {
      this.isExcelLoading = value
    },
    closeFile() {
      this.fileId = null
      this.$accessor.importfile.SET_HEADERS([])
      this.$accessor.importfile.SET_EXCEL({
        node_kid: '',
        rows: [],
        sheet_id: 0,
      })
      this.$accessor.importfile.SET_SHEETS([])
    },
    async save(): Promise<void> {
      try {
        await this.$accessor.importfile.saveTemplate()
        this.$toast.success('Le template a été enregistré avec succès')
      } catch (e) {
        this.$toast.error(`La sauvegarde a échouée: ${e.message}`)
      }
    },
    generateTemplate(): void {
      this.$accessor.importfile.generateTemplate()
    },
    makeItemClass(input: any) {
      const elementClass = []

      elementClass.push('')

      if (input.line === this.infos.startLine.toString()) {
        elementClass.push('start-line')
      }

      if (input.line === this.infos.headersLine.toString()) {
        elementClass.push('header-line')
      }

      return elementClass.join(' ')
    },
    onSelect(selection: AutocompleteItem) {
      this.fileId = selection.kid
      this.setupFile()
    },

    async setupFile() {
      if (!this.fileId) {
        return
      }
      const excel = await this.$api.SpreadSheetGetRows({
        node_kid: this.fileId,
        size: 10,
        sheet_id: this.$accessor.importfile.infos.worksheetId,
      })

      const sheetsOutput = await this.$api.SpreadSheetGetTabs({
        node_kid: this.fileId,
      })

      if (excel) {
        const firstRow = Object.values(excel.rows)[0]
        this.$accessor.importfile.SET_HEADERS(Object.keys(firstRow))
        this.$accessor.importfile.SET_EXCEL(excel)
        this.$accessor.importfile.SET_SHEETS(sheetsOutput.sheets)
      }

      this.dataImport = await this.$api.objectInfo({
        kid: this.fileId,
        get: ['info_user'],
      })
    },

    async setupTemplate() {
      if (!this.$accessor.importfile.target) {
        return
      }
      const object = await this.$api.objectInfo({
        kid: this.$accessor.importfile.target,
      })

      const kjsonRAW = object.kjson
      const json: SavedImportTemplate = JSON.parse(kjsonRAW)

      const parsed = SavedImportTemplateValidator.safeParse(json)

      console.log('parsed', parsed)

      this.$accessor.importfile.loadTemplate(json)
    },
  },
})
