








































import { SpreadSheetTab } from '@knitiv/api-client-javascript'
import Vue from 'vue'

export default Vue.extend({
  computed: {
    sheets(): SpreadSheetTab[] {
      return this.$accessor.importfile.sheets
    },
    start_line: {
      get(): number {
        return this.$accessor.importfile.infos.startLine
      },

      set(start_line: number) {
        this.$accessor.importfile.SET_INFO({ name: 'startLine', value: start_line })
      },
    },

    headers_line: {
      get(): number {
        return this.$accessor.importfile.infos.headersLine
      },

      set(headers_line: number) {
        this.$accessor.importfile.SET_INFO({ name: 'headersLine', value: headers_line })
      },
    },

    worksheet_id: {
      get(): number {
        return this.$accessor.importfile.infos.worksheetId
      },

      set(worksheet_id: number) {
        this.$accessor.importfile.SET_INFO({ name: 'worksheetId', value: worksheet_id })
      },
    },

    name: {
      get(): string {
        return this.$accessor.importfile.name ?? ''
      },

      set(name: string) {
        this.$accessor.importfile.SET_NAME(name)
      },
    },
  },
})
