

























import Vue from 'vue'
import { TemplateErrors } from '@/store/importfile'

interface Data {
  errors: TemplateErrors;
  unsubscribe: (() => void) | undefined;
}

export default Vue.extend({
  components: {},
  data(): Data {
    return {
      errors: {
        filters: [],
        mutators: [],
        objects: [],
      },
      unsubscribe: undefined,
    }
  },
  computed: {
    errorNames() {
      return this.$accessor.importfile.errorNames
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe(async (mutation) => {
      if (mutation.type.startsWith('importfile/')) {
        this.errors = await this.$accessor.importfile.fetchErrors()
      }
    })
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  },
})
