

































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Panel extends Vue {
  @Prop({
    type: String,
  })
  icon: string | undefined

  @Prop({
    type: Boolean,
  })
  loading = false

  @Prop({
    type: String,
  })
  titleBackgroundColor: string | undefined = undefined

  @Prop({
    type: String,
  })
  titleColor: string | undefined = undefined

  titleStyle = {
    color: this.titleColor ?? undefined,
    'background-color': this.titleBackgroundColor ?? undefined,
  }

  mounted() {
    console.log('this.titleStyle', this.titleStyle)
  }
}
