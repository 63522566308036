















































































































import { DataTableHeader } from 'vuetify'
import { DownloadFileInfoOutput } from '@knitiv/api-client-javascript'
import Vue from 'vue'
import { kid } from '@/validators'
import { ObjectToArray } from '@/utils'
import { api } from '@/utils/api'

interface Data {
  tableHeaders: DataTableHeader[];
  rows: any[];

  isLoading: boolean;
  errored: boolean;
  count: number;
  file: Partial<DownloadFileInfoOutput>;

  tab: number;
  sheetNumber: number;
}

export default Vue.extend({
  props: {
    kid: {
      required: true,
      type: String,
      validator: kid,
    },
    itemClass: {
      required: false,
      type: [String, Function],
      default: '',
    },
    hidePager: {
      required: false,
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
      default: 0,
      type: Number,
    },
  },
  data(): Data {
    return {
      tableHeaders: [],
      rows: [],

      isLoading: false,
      errored: false,
      count: 0,
      file: {},

      tab: 0,

      sheetNumber: 0,
    }
  },

  computed: {
    pagination: {
      get(): number {
        return this.value + 1
      },
      set(value: number) {
        this.$emit('input', value - 1)
      },
    },
  },

  watch: {
    async value() {
      this.setLoading(true)
      await this.loadSheet()
      this.setLoading(false)
    },
  },

  async mounted() {
    const { kid } = this
    // console.log('kid', kid)
    if (kid) {
      this.setLoading(true)

      try {
        this.file = await api().downloadFileInfo({
          nodeid: kid,
        })
        // console.log('this.file', this.file)
        const tabs = await api().SpreadSheetGetTabs({
          node_kid: kid,
        })
        // console.log('tabs', tabs)

        this.sheetNumber = tabs.sheets.length

        await this.loadSheet()
      } catch (e) {
        this.errored = true
        console.log(e)
      } finally {
        this.setLoading(false)
      }
    }
  },

  methods: {
    setLoading(value: boolean) {
      this.isLoading = value
      this.$emit('loadingStatusChanged', value)
    },
    async loadSheet() {
      const count = await api().SpreadSheetGetRowsCount({
        node_kid: this.kid,
        sheet_id: this.value,
      })
      this.count = count.size
      const excel = await api().SpreadSheetGetRows({
        node_kid: this.kid,
        size: 10,
        sheet_id: this.value,
      })

      if (excel) {
        const firstRow = Object.values(excel.rows)[0]
        // console.log('firstRow', firstRow)
        this.tableHeaders = [
          {
            text: '',
            value: 'line',
            width: 20,
          },
          ...Object.keys(firstRow)
            .map((key) => ({
              text: key,
              value: key,
              // width: 200
            })),
        ]
        // TODO replace first THEN objectToArray
        const lines = ObjectToArray(excel.rows, 'line')
          .map((line) => {
            const newLine = {}

            const entries = Object.entries(line)
            for (const [key, value] of entries) {
              // TODO keep type infos

              if (Array.isArray(value)) {
                // @ts-ignore
                newLine[key] = value[0]
                /* {
                    type: value[1],
                    text: value[0]
                  } */
              } else {
                // @ts-ignore
                newLine[key] = value
              }
            }

            return newLine
          })
        // console.log('lines', lines)
        this.rows = lines
      }
    },
  },
})
