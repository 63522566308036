var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"objects"},[_c('v-autocomplete',{staticClass:"search-bar",style:({ 'background-color': 'white' }),attrs:{"hide-details":"","prepend-inner-icon":"mdi-magnify","dense":"","clearable":"","outlined":"","single-line":"","items":_vm.availableNodes,"item-value":"id","item-text":"text"},on:{"change":_vm.focusNode}}),_c('div',{attrs:{"id":"objects-wrapper"},on:{"mousemove":_vm.onMouseMoveOnCanvas}},[_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',{ref:"canvas",style:(_vm.canvasStyle),attrs:{"id":"canvas"}},_vm._l((_vm.nodes),function(node){return _c('ObjectNode',{key:node.id,class:{ selected: _vm.selectedNodeId === node.id },attrs:{"node":node},on:{"edit":_vm.onNodeEdit,"delete":_vm.onNodeDelete}})}),1),_c('div',{staticClass:"floating"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"floating-btn",attrs:{"icon":"","disabled":!_vm.initialRelationsFetched,"loading":!_vm.initialRelationsFetched},on:{"click":_vm.generateNode}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}])},[_c('span',[_vm._v("Ajouter un élement")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"floating-btn",attrs:{"icon":""},on:{"click":_vm.repaint}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-brush")])],1)]}}])},[_c('span',[_vm._v("Repaint")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"floating-btn",attrs:{"icon":""},on:{"click":_vm.recenterView}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-camera-metering-center")])],1)]}}])},[_c('span',[_vm._v("Recentrer")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"floating-btn",attrs:{"icon":""},on:{"click":_vm.layoutNodes}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-apache-kafka")])],1)]}}])},[_c('span',[_vm._v("Arranger")])])],1),(_vm.$refs.canvas)?_c('div',{staticClass:"infos"},[_c('div',[_vm._v(" "+_vm._s(_vm.mousePosition)+" "+_vm._s(_vm.roundedZoom)+"% ")])]):_vm._e(),_c('div',{staticClass:"zoom"},[_c('v-btn',{attrs:{"small":"","text":"","icon":""}},[_c('v-icon',{attrs:{"size":"16"},on:{"click":_vm.zoomIn}},[_vm._v(" mdi-magnify-plus ")])],1),_c('v-btn',{attrs:{"small":"","text":"","icon":""}},[_c('v-icon',{attrs:{"size":"16"},on:{"click":_vm.zoomOut}},[_vm._v(" mdi-magnify-minus ")])],1),_c('v-btn',{attrs:{"small":"","text":"","icon":""}},[_c('v-icon',{attrs:{"size":"16"},on:{"click":_vm.zoomReset}},[_vm._v(" mdi-magnify ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }