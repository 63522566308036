
































import Vue from 'vue'
import { nanoid } from 'nanoid'
import MutatorVue from './dataMutator/mutator.vue'
import { Mutators, mutatorFunctions } from '@/models/imports'

export default Vue.extend({
  components: {
    MutatorVue,
  },
  data() {
    return {
      mutatorFunctions,
    }
  },
  computed: {
    isExcelLoaded() {
      const { excel } = this.$accessor.importfile
      return Object.values(excel.rows).length
    },
    mutators(): Mutators | undefined {
      return this.$accessor.importfile.mutators
    },
  },
  methods: {
    addVirtualRow() {
      this.$accessor.importfile.ADD_DATA_MUTATOR({
        modifier: mutatorFunctions[0].value,
        inputs: [],
        output: 'output_name',
        id: nanoid(),
      })
    },
  },
})
