var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper-template-page"},[_c('v-toolbar',{staticClass:"toolbar",attrs:{"dense":"","flat":"","absolute":""}},[_c('v-btn',{attrs:{"icon":"","to":"/importfile/dashboard/templates"}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"floating-btn",attrs:{"icon":""},on:{"click":_vm.generateTemplate}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-code-braces-box")])],1)]}}])},[_c('span',[_vm._v("Générer le code dans la console")])]),(_vm.fileId)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"floating-btn",attrs:{"icon":""},on:{"click":_vm.closeFile}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,36273123)},[_c('span',[_vm._v("Fermer le fichier")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"floating-btn",attrs:{"icon":""},on:{"click":_vm.save}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-all")])],1)]}}])},[_c('span',[_vm._v("Enregistrer")])])],1),_c('splitpanes',{staticClass:"default-theme pane-wrapper",attrs:{"horizontal":""}},[_c('pane',{staticClass:"import-file-pane",attrs:{"size":"25","max-size":"50"}},[_c('v-container',{staticClass:"excel-container",attrs:{"fluid":""}},[(_vm.fileId)?_c('ExcelPreview',{staticClass:"excel-preview",attrs:{"kid":_vm.fileId,"item-class":_vm.makeItemClass,"hide-pager":true},on:{"loadingStatusChanged":_vm.onExcelPreviewLoadingStatusChanged},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var header = ref.header;
return [(header.value !== 'line')?_c('span',[_c('span',[_vm._v(_vm._s(header.value))]),(_vm.filledHeaders.includes(_vm.headerAndLine[header.value]))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'span',attrs,false),on),[_vm._v(" ("+_vm._s(_vm.filledHeaders.filter(function (x) { return x === _vm.headerAndLine[header.value]; }).length)+" éléments liés) ")])]}}],null,true)},[_c('span',[_vm._v("Tooltip")])]):_vm._e()],1):_vm._e()]}},{key:"item",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"cell-value"},[_vm._v(_vm._s(value)+" ")])]}}],null,false,4236386461),model:{value:(_vm.currentSheetId),callback:function ($$v) {_vm.currentSheetId=$$v},expression:"currentSheetId"}}):_c('div',[_c('MfInput',{attrs:{"card-from":0,"card-to":1,"order":[_vm.orderFile],"placeholder":"Choisissez une source de donnée"},on:{"selection":_vm.onSelect}})],1)],1)],1),(_vm.fileId)?_c('pane',{attrs:{"min-size":"25"}},[_c('v-card',{staticClass:"bottom-pane",attrs:{"disabled":_vm.isExcelLoading || !_vm.fileId}},[_c('v-card-text',[_c('v-tabs',{attrs:{"centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_vm._l((_vm.tabs),function(item){return _c('v-tab',{key:item.id,attrs:{"disabled":item.id === 'header_filters' && _vm.filtersLength <= 0}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2),_c('v-tabs-items',{staticClass:"tabs",attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item){
var _obj;
return _c('v-tab-item',{key:item.id,staticClass:"tab-item",class:( _obj = {}, _obj[item.id] = true, _obj ),attrs:{"eager":""}},[_c(item.to,{key:item.id,tag:"component",attrs:{"id":item.id,"name":item.id}})],1)}),1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }