












import Vue from 'vue'

export default Vue.extend({
  name: 'NodeBase',
  methods: {
    onClick() {
      console.log('ok')
    },
  },
})
